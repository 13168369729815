@import '../../input/style/mixin';

.form-control-validation(
  @text-color: @input-color;
  @border-color: @input-border-color;
  @background-color: @input-bg;
  @hoverBorderColor: @primary-color-hover;
  @outlineColor: @primary-color-outline;
) {
  .@{ant-prefix}-form-item-split {
    color: @text-color;
  }
  // 输入框的不同校验状态
  :not(.@{ant-prefix}-input-disabled):not(.@{ant-prefix}-input-borderless).@{ant-prefix}-input,
  :not(.@{ant-prefix}-input-affix-wrapper-disabled):not(.@{ant-prefix}-input-affix-wrapper-borderless).@{ant-prefix}-input-affix-wrapper {
    &,
    &:hover {
      background-color: @background-color;
      border-color: @border-color;
    }

    &:focus,
    &-focused {
      .active(@border-color, @hoverBorderColor, @outlineColor);
    }
  }

  .@{ant-prefix}-calendar-picker-open .@{ant-prefix}-calendar-picker-input {
    .active(@border-color, @hoverBorderColor, @outlineColor);
  }

  .@{ant-prefix}-input-prefix {
    color: @text-color;
  }

  .@{ant-prefix}-input-group-addon,
  .@{ant-prefix}-input-number-group-addon {
    color: @text-color;
    border-color: @border-color;
  }

  .has-feedback {
    color: @text-color;
  }
}

// Reset form styles
// -----------------------------
// Based on Bootstrap framework
.reset-form() {
  legend {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
    color: @text-color-secondary;
    font-size: @font-size-lg;
    line-height: inherit;
    border: 0;
    border-bottom: @border-width-base @border-style-base @border-color-base;
  }

  label {
    font-size: @font-size-base;
  }

  input[type='search'] {
    box-sizing: border-box;
  }

  // Position radios and checkboxes better
  input[type='radio'],
  input[type='checkbox'] {
    line-height: normal;
  }

  input[type='file'] {
    display: block;
  }

  // Make range inputs behave like textual form controls
  input[type='range'] {
    display: block;
    width: 100%;
  }

  // Make multiple select elements height not fixed
  select[multiple],
  select[size] {
    height: auto;
  }

  // Focus for file, radio, and checkbox
  input[type='file']:focus,
  input[type='radio']:focus,
  input[type='checkbox']:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  // Adjust output element
  output {
    display: block;
    padding-top: 15px;
    color: @input-color;
    font-size: @font-size-base;
    line-height: @line-height-base;
  }
}
