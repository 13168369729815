.@{badge-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &:not(&-not-a-wrapper) &-count,
  &:not(&-not-a-wrapper) &-dot,
  &:not(&-not-a-wrapper) .@{number-prefix-cls}-custom-component {
    .@{badge-prefix-cls}-rtl & {
      right: auto;
      left: 0;
      direction: ltr;
      transform: translate(-50%, -50%);
      transform-origin: 0% 0%;
    }
  }

  &-rtl&:not(&-not-a-wrapper) .@{number-prefix-cls}-custom-component {
    right: auto;
    left: 0;
    transform: translate(-50%, -50%);
    transform-origin: 0% 0%;
  }

  &-status {
    &-text {
      .@{badge-prefix-cls}-rtl & {
        margin-right: 8px;
        margin-left: 0;
      }
    }
  }
}

.@{ribbon-prefix-cls}-rtl {
  direction: rtl;
  &.@{ribbon-prefix-cls}-placement-end {
    right: unset;
    left: -8px;
    border-bottom-right-radius: @border-radius-sm;
    border-bottom-left-radius: 0;
    .@{ribbon-prefix-cls}-corner {
      right: unset;
      left: 0;
      border-color: currentColor currentColor transparent transparent;

      &::after {
        border-color: currentColor currentColor transparent transparent;
      }
    }
  }
  &.@{ribbon-prefix-cls}-placement-start {
    right: -8px;
    left: unset;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: @border-radius-sm;
    .@{ribbon-prefix-cls}-corner {
      right: 0;
      left: unset;
      border-color: currentColor transparent transparent currentColor;

      &::after {
        border-color: currentColor transparent transparent currentColor;
      }
    }
  }
}
