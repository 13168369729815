.App {
  text-align: center;
}

body {
  background-color: #aaaaaa;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

thead > tr > th {
  background-color: #40a9ff !important;
  color : white !important; 
  text-align: center !important;
}

[data-theme="dark"] .site-layout-background {
  background: white;
}

.ant-modal-content {
  border-radius: 12px !important;
}

.ant-modal-header {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  background-color: #40a9ff !important;
}

.ant-modal-title {
  color : white !important; 
}

.ant-modal-close-icon {
  color : white !important; 
}

.ql-editor{
  min-height:240px;
}

.site-back-top-basic {
  color: rgba(64, 64, 64, 0.6);
}

th, td {
  padding: 8px;
}
